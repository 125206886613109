import packageJson from "../../package.json";
import useVersion from "../hooks/useVersion";
import WalletConnectProvider from "../providers/wallet-connector/WalletConnectProvider";

function About() {
  const version = useVersion();
  return (
    <div>
      <h1>About</h1>
      <h2>App Version: {packageJson.version}</h2>
      <h2>Contract Version: {version}</h2>
    </div>
  );
}

export default function AboutPage() {
  return (
    <WalletConnectProvider>
      <About />
    </WalletConnectProvider>
  );
}
