import { Box, Container, Typography } from '@mui/material';

export default function Unauthenticated() {
  return (
    <Container>
      <Box
        sx={{
          textAlign: 'center',
          marginTop: '32vh',
        }}
      >
        <Typography variant='h5'>{`You don't have permission to view this page`}</Typography>
        <Typography variant='subtitle1'>{`403 Error. Forbidden`}</Typography>
      </Box>
    </Container>
  );
}
