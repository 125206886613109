import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Ownership } from "../../../../../apis/__generated__/graphql";
import useMetadata from "../../../../../hooks/useMetadata";
import { formatEther } from "ethers";
import StatusChip from "../../../../shared/StatusChip";
import WithdrawButton from "../buttons/WithdrawButton";
import { OwnershipTypes } from "../../../../../libraries/utils/constants";
import { useState } from "react";

export interface OwnershipTableProps {
  ownerships: Ownership[];
  loading: boolean;
  refetch: () => void;
}

export default function OwnershipTable({
  ownerships,
  loading,
  refetch,
}: OwnershipTableProps) {
  const [selectedRow, setSelectedRow] = useState<Ownership | undefined>(
    undefined
  );
  const { symbol } = useMetadata();

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "ID",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      width: 70,
      filterable: false,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "amount",
      headerName: `Amount (${symbol})`,
      minWidth: 120,
      type: "number",
      valueGetter: ({ value }) => formatEther(value),
      flex: 0.15,
    },
    {
      field: "lockupDeadline",
      headerName: "Lockup Deadline",
      renderCell: ({ value }) => {
        return Number(value) == 0
          ? "NOT SET"
          : dayjs(value).format("MM/DD/YYYY, HH:mm A");
      },
      minWidth: 200,
      type: "date",
      valueGetter: ({ value }) => dayjs.unix(value).toDate(),
      valueFormatter: ({ value }) => {
        return Number(value) == 0
          ? "NOT SET"
          : dayjs(value).format("MM/DD/YYYY, HH:mm A");
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      type: "singleSelect",
      valueOptions: [
        { value: OwnershipTypes.PROVISIONAL, label: "PROVISIONAL" },
        { value: OwnershipTypes.OWNERSHIP_LOCKUP, label: "OWNERSHIP_LOCKUP" },
        { value: OwnershipTypes.VESTING_LOCKUP, label: "VESTING_LOCKUP" },
      ],
      renderCell: ({ value }) => {
        return <StatusChip status={value} />;
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: ({ row }) => [
        <WithdrawButton
          key="Withdraw"
          disabled={
            (row.status !== OwnershipTypes.VESTING_LOCKUP &&
              row.status !== OwnershipTypes.OWNERSHIP_LOCKUP) ||
            dayjs.unix(row.lockupDeadline).isAfter(dayjs()) ||
            row.lockupDeadline == 0
          }
          onSelectedRow={() => setSelectedRow(row)}
          onUnselectedRow={() => setSelectedRow(undefined)}
          row={selectedRow}
          refetch={refetch}
        />,
      ],
    },
  ];

  return (
    <Paper>
      <DataGridPro
        rows={ownerships}
        columns={columns}
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarExport
                printOptions={{ disableToolbarButton: true }}
              />
            </GridToolbarContainer>
          ),
        }}
      />
    </Paper>
  );
}
