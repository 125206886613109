import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

export interface MobileSidebarProviderProps {}

export interface MobileSidebarContextValue {
  visible: boolean;
  open: boolean;
  onToggle: () => void;
  setVisible: (state: boolean) => void;
}

export const MobileSidebarContext = createContext<MobileSidebarContextValue>({
  visible: false,
  open: false,
  onToggle: () => {},
  setVisible: (state: boolean) => {},
});

export const MobileSidebarProvider = ({
  children,
}: PropsWithChildren<MobileSidebarProviderProps>) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const onToggle = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <MobileSidebarContext.Provider
      value={{
        visible,
        open,
        onToggle,
        setVisible,
      }}
    >
      {children}
    </MobileSidebarContext.Provider>
  );
};

export const useMobileSidebarVisibleState = () => {
  const { visible, setVisible } = useContext(MobileSidebarContext);
  return { visible, setVisible };
};

export const useMobileSidebarState = () => {
  const { open, onToggle } = useContext(MobileSidebarContext);
  return { open, onToggle };
};
