import { GridColDef, DataGrid } from "@mui/x-data-grid";
import * as dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import ConfirmButton from "../buttons/ConfirmButton";
import { Stack, Typography } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import {
  ExtendLockupPeriodProposal,
  Ownership,
} from "../../../../../apis/__generated__/graphql";
import RevokeProposalButton from "../buttons/RevokeProposalButton";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";

export interface ExtendOwnershipProposalTableProps {
  proposals: ExtendLockupPeriodProposal[];
  loading: boolean;
  refetch: () => void;
  ownerships: Ownership[];
}

export default function ExtendOwnershipProposalTable({
  proposals,
  loading,
  refetch,
  ownerships,
}: ExtendOwnershipProposalTableProps) {
  const [selectedRow, setSelectedRow] = useState<
    ExtendLockupPeriodProposal | undefined
  >(undefined);

  const columns: GridColDef<ExtendLockupPeriodProposal>[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      minWidth: 120,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 120,
      valueGetter: ({ value }) => {
        return value.slice(0, 5) + "…" + value.slice(-5);
      },
    },
    {
      field: "extensionPeriod",
      headerName: "Deadline",
      minWidth: 400,
      renderCell: ({ value, row }) => {
        const ownership = ownerships.find(
          (ownership) =>
            ownership.owner == row.owner && ownership.index == row.index
        );

        const currentDeadline =
          ownership?.lockupDeadline != 0
            ? dayjs
                .unix(Number(ownership?.lockupDeadline))
                .format("MM/DD/YYYY, hh:mm A")
            : "NOT SET";
        const updateDeadline = dayjs
          .unix(Number(ownership?.lockupDeadline) + Number(value))
          .format("MM/DD/YYYY, hh:mm A");

        return `${currentDeadline} -> ${updateDeadline}`;
      },
    },
    {
      field: "confirms",
      headerName: "Confirms",
      width: 150,
      renderCell: ({ row }) => {
        const numberOfConfirmations =
          (row.administratorApproved ? 1 : 0) + (row.keyKeeperApproved ? 1 : 0);

        return <>{`${numberOfConfirmations}/2`}</>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Administrator:{" "}
              {row.administratorApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
            <Typography fontSize={14} display="flex">
              Key Keeper:{" "}
              {row.keyKeeperApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 150,
      getActions: ({ row }) => {
        return [
          <ConfirmButton
            key="Confirm"
            disabled={row.administratorApproved}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
          <RevokeProposalButton
            key="Revoke"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
        ];
      },
    },
  ];

  return (
    <Paper>
      <DataGrid
        rows={proposals}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={70}
      />
    </Paper>
  );
}
