import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import {
  FormContainer,
  TextFieldElement,
  DateTimePickerElement,
} from "react-hook-form-mui";
import { Stack } from "@mui/material";
import * as dayjs from "dayjs";
import useMetadata from "../../../../../hooks/useMetadata";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import useBalances from "../../../../../hooks/useBalances";
import { ZeroAddress, formatEther, isAddress, parseEther } from "ethers";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";

export interface AddModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface AddValues {
  owner: string;
  amount: string;
  lockupDeadline: dayjs.Dayjs;
}

export default function AddModal({ open, onClose, refetch }: AddModalProps) {
  const { symbol } = useMetadata();
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();

  const {
    data: { totalSupply, totalLocked, totalProvisional },
    refetch: refetchBalance,
  } = useBalances();

  const totalUnallocated = totalSupply - totalLocked - totalProvisional;

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  const onSubmit = async (data: AddValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.createProvisionalOwnership(
        data.owner,
        parseEther(data.amount),
        data.lockupDeadline ? dayjs.default(data.lockupDeadline).unix() : 0
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
      refetchBalance();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>
              Create Provisional Ownership
            </Typography>
            <Box>
              <FormContainer onSuccess={onSubmit}>
                <Stack direction="column" spacing={2}>
                  <TextFieldElement
                    name="owner"
                    label="Owner"
                    validation={{
                      required: true,
                      validate: (value) => {
                        if (!isAddress(value) || value == ZeroAddress)
                          return "Address is not valid";
                        return true;
                      },
                    }}
                  />
                  <Stack direction="column" spacing={1}>
                    <TextFieldElement
                      name="amount"
                      label="Amount"
                      validation={{
                        required: true,
                        validate: (value) => {
                          try {
                            const amount = parseEther(value);
                            if (amount > BigInt(totalUnallocated))
                              return "Exceeds available amount";
                            else if (amount == BigInt(0))
                              return "Must greater than zero";
                            return true;
                          } catch (e) {
                            return "Invalid number";
                          }
                        },
                      }}
                    />
                    <Typography variant="caption">
                      Available {formatEther(totalUnallocated)} {symbol}
                    </Typography>
                  </Stack>
                  <DateTimePickerElement
                    name="lockupDeadline"
                    label="Lockup Deadline"
                    disablePast
                    ampm={false}
                  />
                  <Button
                    type={"submit"}
                    startIcon={<AddIcon />}
                    variant="contained"
                  >
                    Create Provisional Ownership
                  </Button>
                </Stack>
              </FormContainer>
            </Box>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
