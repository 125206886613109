import Box from "@mui/material/Box";
import { Grid, Stack, Typography } from "@mui/material";
import DepositButton from "./components/buttons/DepositButton";
import useMetadata from "../../../hooks/useMetadata";
import useBalances from "../../../hooks/useBalances";
import { formatEther } from "ethers";

export default function BalancePage() {
  const { symbol } = useMetadata();

  const {
    data: { totalSupply, totalLocked, totalProvisional },
    refetch,
  } = useBalances();

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2 }}
        spacing={1}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Balances
        </Typography>
        <DepositButton refetch={refetch} />
      </Stack>

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box boxShadow={1} padding={1} borderRadius={1} height={"100%"}>
              <Typography variant="subtitle1">Balance</Typography>
              <Typography>{`${formatEther(totalSupply)} ${symbol}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box boxShadow={1} padding={1} borderRadius={1} height={"100%"}>
              <Typography variant="subtitle1">Locked Amount</Typography>
              <Typography>{`${formatEther(totalLocked)} ${symbol}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box boxShadow={1} padding={1} borderRadius={1} height={"100%"}>
              <Typography variant="subtitle1">Provisional Amount</Typography>
              <Typography>{`${formatEther(
                totalProvisional
              )} ${symbol}`}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
