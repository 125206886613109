import { Tooltip } from "@mui/material";
import { useState } from "react";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import RevokeModal from "../modals/RevokeModal";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Role } from "../../../../../apis/__generated__/graphql";

export interface RevokeButtonProps {
  refetch: () => void;
  row?: Role;
  onSelectedRow: () => void;
  onUnselectedRow: () => void;
}

export default function RevokeButton({
  refetch,
  row,
  onSelectedRow,
  onUnselectedRow,
}: RevokeButtonProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onSelectedRow();
  };

  const handleClose = () => {
    setOpen(false);
    onUnselectedRow();
  };

  return (
    <>
      <GridActionsCellItem
        label="Revoke"
        icon={
          <Tooltip title="Revoke">
            <PersonRemoveIcon />
          </Tooltip>
        }
        onClick={handleOpen}
      />
      {row && (
        <RevokeModal
          row={row}
          open={open}
          onClose={handleClose}
          refetch={refetch}
        />
      )}
    </>
  );
}
