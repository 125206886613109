import { TokenDistributionManager__factory } from "@gu-corp/joc-token-manager-contracts";
import { useProvider, useSigner } from "@gusdk/gu-wallet-connector";
import { env } from "../libraries/utils/env";
import { useMemo } from "react";

export function useContract() {
  const provider = useProvider();
  const signer = useSigner();

  const contract = useMemo(() => {
    return TokenDistributionManager__factory.connect(
      env.REACT_APP_CONTRACT_ADDRESS?.toLowerCase() as string,
      signer || provider
    );
  }, [signer, provider]);

  return contract;
}
