import { useEffect, useState } from "react";
import { useContract } from "./useContract";

export default function useAuthorized(role: string, account: string) {
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);

  const contract = useContract();

  useEffect(() => {
    setLoading(true);
    (async () => {
      if (role !== "") {
        try {
          setLoading(true);
          const authorized = await contract.hasRole(role, account);
          setAuthorized(authorized);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }
    })();
  }, [account, role, contract]);

  return { authorized, loading };
}
