import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import * as dayjs from "dayjs";
import { FormContainer, DateTimePickerElement } from "react-hook-form-mui";
import { Stack } from "@mui/material";
import { Ownership } from "../../../../../apis/__generated__/graphql";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";

export interface SetLockupPeriodModalProps {
  open: boolean;
  onClose: () => void;
  row: Ownership;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface SetLockupPeriodValues {
  index: number;
  owner: string;
  deadline: dayjs.Dayjs;
}

export default function SetLockupPeriodModal({
  open,
  onClose,
  row,
  refetch,
}: SetLockupPeriodModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();

  const onSubmit = async (data: SetLockupPeriodValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.setLockupPeriod(
        data.owner,
        data.index,
        dayjs.default(data.deadline).unix()
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (/User rejected the request/.test(error))
        alert.showAlert(AlertType.ERROR, "User denied transaction signature.");
      else if (/cannot estimate gas/.test(error))
        alert.showAlert(AlertType.ERROR, "Cannot estimate gas.");
      else alert.showAlert(AlertType.ERROR, "Transaction reverted.");

      setStep(Steps.Error);
    }
  };

  const defaultValues: SetLockupPeriodValues = {
    owner: row.owner,
    index: row.index,
    deadline: dayjs.unix(dayjs.default().unix()),
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>
              Set Lockup Period
            </Typography>
            <Box>
              <FormContainer onSuccess={onSubmit} defaultValues={defaultValues}>
                <Stack direction="column" spacing={2}>
                  <Stack direction="column" spacing={1}>
                    <DateTimePickerElement
                      name="deadline"
                      label="Deadline"
                      disablePast
                      validation={{
                        required: true,
                      }}
                      ampm={false}
                    />
                  </Stack>

                  <Button
                    type={"submit"}
                    startIcon={<SettingsIcon />}
                    variant="contained"
                  >
                    Confirm
                  </Button>
                </Stack>
              </FormContainer>
            </Box>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
