import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCallback, useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";

import { useQuery } from "@apollo/client";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { useContract } from "../../../../../hooks/useContract";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import useRoles from "../../../../../hooks/useRoles";
import { GET_ROLES } from "../../../../../libraries/queries/roles";
import { isAddress, ZeroAddress } from "ethers";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
import DangerConfirmModal from "../../../../shared/DangerConfirmModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

export interface TransferOwnershipModalProps {
  open: boolean;
  onClose: () => void;
}

export interface TransferOwnershipValues {
  address: string;
}

export default function TransferOwnershipModal({
  open,
  onClose,
}: TransferOwnershipModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const [values, setValues] = useState<TransferOwnershipValues | null>(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const contract = useContract();

  const { ADMINISTRATOR_ROLE, OWNER_ROLE } = useRoles();
  const { data } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: ADMINISTRATOR_ROLE,
      },
    },
    skip: !ADMINISTRATOR_ROLE,
  });

  const { data: ownerData } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: OWNER_ROLE,
      },
    },
    skip: !OWNER_ROLE,
  });

  const admins = data?.roles || [];
  const owner = ownerData?.roles?.[0];

  const options = admins
    .filter((admin) => admin.account !== owner?.account)
    .map((role) => ({
      id: role.account,
      label: role.account,
    }));

  const onSubmit = async (data: TransferOwnershipValues) => {
    setOpenConfirm(true);
    setValues(data);
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
    setValues(null);
  }, [onClose]);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      if (!values) throw Error("Missing values");

      setStep(Steps.Waiting);
      const tx = await contract.transferOwnership(values.address);
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      window.location.reload();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  }, [alert, contract, values]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {step === Steps.Creating && (
            <Box>
              <Typography variant="h6" component="h2">
                Transfer Ownership
              </Typography>
              <Typography sx={{ mb: 2 }}>
                {`This actions will transfer ownership to new owner. After transferring you can't access this page, 
              please checking new owner address carefully`}
              </Typography>
              <FormContainer onSuccess={onSubmit}>
                <Stack direction="column" spacing={2}>
                  <SelectElement
                    name={"address"}
                    label="Address"
                    validation={{
                      required: true,
                      validate: (value) => {
                        if (!isAddress(value) || value == ZeroAddress)
                          return "Address is not valid";
                        return true;
                      },
                    }}
                    options={options}
                  />
                  <Button type="submit" variant="contained">
                    Transfer
                  </Button>
                </Stack>
              </FormContainer>
            </Box>
          )}

          {step === Steps.Waiting && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
            </Box>
          )}

          {step === Steps.Finalizing && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>
                Waiting for block finalizing
              </Typography>
            </Box>
          )}

          {step === Steps.Error && (
            <Box sx={{ textAlign: "center" }}>
              <ErrorIcon />
              <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
              <Button
                onClick={() => {
                  setStep(Steps.Creating);
                }}
              >
                Retry
              </Button>
            </Box>
          )}

          {step === Steps.Finalized && (
            <Box sx={{ textAlign: "center" }}>
              <DoneIcon />
              <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
            </Box>
          )}
        </Box>
      </Modal>
      <DangerConfirmModal
        open={openConfirm}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirm}
      />
    </>
  );
}
