import { Box, CircularProgress, Container, Typography } from "@mui/material";

export default function Authenticating() {
  return (
    <Container>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "32vh",
        }}
      >
        <Typography variant="h5">{`Checking permission to view this page`}</Typography>
        <CircularProgress />
      </Box>
    </Container>
  );
}
