import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Stack } from "@mui/material";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { ZeroAddress, isAddress } from "ethers";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
export interface AddModalProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface AddValues {
  newKeyKeeper: string;
}

export default function AddModal({ open, onClose, refetch }: AddModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  const onSubmit = async (data: AddValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.createGrantKeyKeeperRoleProposal(
        data.newKeyKeeper
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>
              Create Grant Key Keeper Proposal
            </Typography>
            <Box>
              <FormContainer onSuccess={onSubmit}>
                <Stack direction="column" spacing={2}>
                  <TextFieldElement
                    name={"newKeyKeeper"}
                    label="Key keeper's address"
                    validation={{
                      required: true,
                      validate: (value) => {
                        if (!isAddress(value) || value == ZeroAddress)
                          return "Address is not valid";
                        return true;
                      },
                    }}
                  />
                  <Button
                    type={"submit"}
                    startIcon={<AddIcon />}
                    variant="contained"
                  >
                    Create
                  </Button>
                </Stack>
              </FormContainer>
            </Box>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
