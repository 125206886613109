import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { FormContainer, TextFieldElement, useWatch } from "react-hook-form-mui";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface GrantModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
}

export interface DangerConfirmValues {
  confirm: string;
}

export default function DangerConfirmModal({
  open,
  onClose,
  onConfirm,
}: GrantModalProps) {
  const onSubmit = async (data: DangerConfirmValues) => {
    onConfirm();
    onClose();
  };

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box>
          <Typography variant="h6" component="h2">
            Danger Action
          </Typography>

          <Typography mb={2}>
            To confirm, type "confirm" in the box below
          </Typography>
          <FormContainer onSuccess={onSubmit}>
            <Form />
          </FormContainer>
        </Box>
      </Box>
    </Modal>
  );
}

function Form() {
  const [confirm] = useWatch({
    name: ["confirm"],
  });

  return (
    <Stack direction="column" spacing={2}>
      <TextFieldElement name="confirm" label={`type "confirm"`} />
      <Button
        type="submit"
        variant="contained"
        disabled={confirm != "confirm"}
        color="error"
      >
        Confirm
      </Button>
    </Stack>
  );
}
