import { gql } from "../../apis/__generated__";

export const GET_ROLES = gql(/* GraphQL */ `
  query roles($first: Int, $skip: Int, $where: Role_filter) {
    roles(first: $first, skip: $skip, where: $where) {
      id
      account
    }
  }
`);
