import Drawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import Box from "@mui/material/Box";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import SettingsIcon from "@mui/icons-material/Settings";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import useRoles from "../../hooks/useRoles";
import Unauthenticated from "../Layout/UnAuthenticated";
import { useEffect, useState } from "react";
import {
  useMobileSidebarState,
  useMobileSidebarVisibleState,
} from "../../providers/mobile-sidebar/MobileSidebarProvider";
import { Collapse, useMediaQuery } from "@mui/material";
import { useAccount } from "@gusdk/gu-wallet-connector";
import useAuthorized from "../../hooks/useAuthorized";
import theme from "../../theme";
import { Link, Outlet, useLocation } from "react-router-dom";
import Authenticating from "../Layout/Authenticating";

const DRAWER_WIDTH = 240;
const DRAWER_MEDIUM_WIDTH = 200;

const LINKS = [
  { text: "Admins", href: "/owner/admins", icon: SupervisorAccountIcon },
  { text: "Balances", href: "/owner/balances", icon: CurrencyYenIcon },
  { text: "Settings", href: "/owner/settings", icon: SettingsIcon },
];

const PROPOSAL_LINKS = [
  {
    text: "Upgradable",
    href: "/owner/proposals/upgradable",
  },
];

export default function OwnerLayout() {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const { OWNER_ROLE } = useRoles();
  const handleClick = () => {
    setOpen(!open);
  };
  const { account } = useAccount();
  const { authorized, loading } = useAuthorized(OWNER_ROLE, account);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { setVisible } = useMobileSidebarVisibleState();
  const { open: mobileOpen } = useMobileSidebarState();
  useEffect(() => {
    if (authorized) {
      setVisible(true);
    }
  }, [authorized, setVisible]);

  if (loading) {
    return <Authenticating />;
  }

  if (!authorized) {
    return <Unauthenticated />;
  }

  return (
    <>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
            top: ["48px", "56px", "64px"],
            height: "auto",
            bottom: 0,
          },
          display: { xs: "block", sm: "none" },
        }}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton
                component={Link}
                to={href}
                selected={href == location.pathname}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText primary="Proposals" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {PROPOSAL_LINKS.map((link) => (
                <ListItemButton
                  component={Link}
                  to={link.href}
                  sx={{ pl: 4 }}
                  selected={link.href == location.pathname}
                >
                  <ListItemText primary={link.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider sx={{ mt: "auto" }} />
      </Drawer>
      <Drawer
        sx={{
          width: isMediumScreen ? DRAWER_MEDIUM_WIDTH : DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isMediumScreen ? DRAWER_MEDIUM_WIDTH : DRAWER_WIDTH,
            boxSizing: "border-box",
            top: ["48px", "56px", "64px"],
            height: "auto",
            bottom: 0,
          },
          display: { xs: "none", sm: "block" },
        }}
        variant="permanent"
        anchor="left"
        open={mobileOpen}
      >
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton
                component={Link}
                to={href}
                selected={href == location.pathname}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <HowToVoteIcon />
            </ListItemIcon>
            <ListItemText primary="Proposals" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {PROPOSAL_LINKS.map((link) => (
                <ListItemButton
                  component={Link}
                  to={link.href}
                  sx={{ pl: 4 }}
                  selected={link.href == location.pathname}
                >
                  <ListItemText primary={link.text} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
        <Divider sx={{ mt: "auto" }} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          ml: `${
            isSmallScreen
              ? 0
              : isMediumScreen
              ? DRAWER_MEDIUM_WIDTH
              : DRAWER_WIDTH
          }px`,
          p: [3],
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
