import { useContract } from "./useContract";
import { useCallback, useEffect, useState } from "react";

export default function useMaximumExtendPeriod() {
  const contract = useContract();
  const [maximumExtendPeriod, setMaximumExtendPeriod] = useState(0);

  useEffect(() => {
    (async () => {
      const maximumExtendPeriod = await contract.maximumLockupExtendPeriod();
      setMaximumExtendPeriod(Number(maximumExtendPeriod));
    })();
  }, [contract]);

  const refetch = useCallback(async () => {
    const maximumExtendPeriod = await contract.maximumLockupExtendPeriod();
    setMaximumExtendPeriod(Number(maximumExtendPeriod));
  }, [contract]);

  return { data: maximumExtendPeriod, refetch };
}
