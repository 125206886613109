import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { Stack } from "@mui/material";
import { Ownership } from "../../../../../apis/__generated__/graphql";
import useMetadata from "../../../../../hooks/useMetadata";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { parseEther, formatEther } from "ethers";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";

export interface SplitModalProps {
  open: boolean;
  onClose: () => void;
  row: Ownership;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface SplitValues {
  owner: string;
  index: number;
  amount: string;
}

export default function SplitModal({
  open,
  onClose,
  row,
  refetch,
}: SplitModalProps) {
  const { symbol } = useMetadata();
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();

  const onSubmit = async (data: SplitValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract["splitTokenOwnership(address,uint256,uint256)"](
        data.owner,
        data.index,
        parseEther(data.amount)
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  const defaultValues: SplitValues = {
    owner: row.owner,
    index: row.index,
    amount: formatEther(row.amount),
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2">
              Split Ownership
            </Typography>
            <Typography variant="body1" component="h2" sx={{ mb: 1 }}>
              Action will split ownership by amount
            </Typography>
            <Box>
              <FormContainer onSuccess={onSubmit} defaultValues={defaultValues}>
                <Stack direction="column" spacing={2}>
                  <Stack direction="column" spacing={1}>
                    <TextFieldElement
                      name="amount"
                      label="Amount"
                      validation={{
                        required: true,
                        validate: (value) => {
                          try {
                            const amount = parseEther(value);
                            if (amount >= BigInt(row.amount))
                              return "Exceeds available amount";
                            else if (amount === BigInt(0))
                              return "Must greater than zero";
                            return true;
                          } catch (e) {
                            return "Invalid number";
                          }
                        },
                      }}
                    />
                    <Typography variant="caption">
                      Available {formatEther(BigInt(row.amount))} {symbol}
                    </Typography>
                  </Stack>

                  <Button
                    type={"submit"}
                    startIcon={<CallSplitIcon />}
                    variant="contained"
                  >
                    Split
                  </Button>
                </Stack>
              </FormContainer>
            </Box>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
