import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import RevokeKeyKeeperProposalTable from "./tables/RevokeKeyKeeperProposalTable";
import { GET_REVOKE_KEY_KEEPER_PROPOSALS } from "../../../../libraries/queries/proposals";
import {
  RevokeKeyKeeperProposal,
  Role,
} from "../../../../apis/__generated__/graphql";
import AddButton from "./buttons/AddButton";
import { GET_ROLES } from "../../../../libraries/queries/roles";
import useRoles from "../../../../hooks/useRoles";
import { ProposalStatus } from "../../../../libraries/types/proposal";
import ProposalTabs from "../../../shared/ProposalTabs";
import ProceedTable from "./tables/ProceedTable";

export default function RevokeKeyKeeperProposalPage() {
  const { KEY_KEEPER_ROLE } = useRoles();
  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_REVOKE_KEY_KEEPER_PROPOSALS, {
    variables: {
      where: {
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.revokeKeyKeeperProposals ||
    []) as RevokeKeyKeeperProposal[];

  const { data } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: KEY_KEEPER_ROLE,
      },
    },
    skip: !KEY_KEEPER_ROLE,
  });
  const keyKeepers = (data?.roles || []) as Role[];

  const { data: proceedData, loading: proceedLoading } = useQuery(
    GET_REVOKE_KEY_KEEPER_PROPOSALS,
    {
      variables: {
        where: {
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );
  const proceedProposals = (proceedData?.revokeKeyKeeperProposals ||
    []) as RevokeKeyKeeperProposal[];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Revoke Key Keeper Proposal
        </Typography>
        <AddButton refetch={refetch} keyKeepers={keyKeepers} />
      </Stack>
      <ProposalTabs
        processingTab={
          <RevokeKeyKeeperProposalTable
            proposals={proposals}
            loading={loading}
            refetch={refetch}
          />
        }
        proceedTab={
          <ProceedTable loading={proceedLoading} proposals={proceedProposals} />
        }
      />
    </Box>
  );
}
