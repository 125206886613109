import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import GrantKeyKeeperProposalTable from "./tables/GrantKeyKeeperProposalTable";
import { GET_GRANT_KEY_KEEPER_PROPOSALS } from "../../../../libraries/queries/proposals";
import { GrantKeyKeeperProposal } from "../../../../apis/__generated__/graphql";
import AddButton from "./buttons/AddButton";
import { ProposalStatus } from "../../../../libraries/types/proposal";
import ProposalTabs from "../../../shared/ProposalTabs";
import ProceedTable from "./tables/ProceedTable";

export default function GrantKeyKeeperProposalPage() {
  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_GRANT_KEY_KEEPER_PROPOSALS, {
    variables: {
      where: {
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.grantKeyKeeperProposals ||
    []) as GrantKeyKeeperProposal[];

  const { data: proceedData, loading: proceedLoading } = useQuery(
    GET_GRANT_KEY_KEEPER_PROPOSALS,
    {
      variables: {
        where: {
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );

  const proceedProposals = (proceedData?.grantKeyKeeperProposals ||
    []) as GrantKeyKeeperProposal[];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Grant Key Keeper Proposal
        </Typography>
        <AddButton refetch={refetch} />
      </Stack>
      <ProposalTabs
        processingTab={
          <GrantKeyKeeperProposalTable
            proposals={proposals}
            loading={loading}
            refetch={refetch}
          />
        }
        proceedTab={
          <ProceedTable loading={proceedLoading} proposals={proceedProposals} />
        }
      />
    </Box>
  );
}
