import { GridColDef, DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import ConfirmButton from "../buttons/ConfirmButton";
import { TransferTokenOwnershipProposal } from "../../../../../apis/__generated__/graphql";
import RevokeProposalButton from "../buttons/RevokeProposalButton";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";

export interface TransferTokenOwnershipProposalTableProps {
  proposals: TransferTokenOwnershipProposal[];
  loading: boolean;
  refetch: () => void;
}

export default function TransferTokenOwnershipProposalTable({
  proposals,
  loading,
  refetch,
}: TransferTokenOwnershipProposalTableProps) {
  const [selectedRow, setSelectedRow] = useState<
    TransferTokenOwnershipProposal | undefined
  >(undefined);

  const columns: GridColDef<TransferTokenOwnershipProposal>[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      width: 120,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "newOwner",
      headerName: "New Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "actions",
      type: "actions",
      width: 150,
      getActions: ({ row }) => {
        return [
          <ConfirmButton
            key="Confirm"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
          <RevokeProposalButton
            key="Revoke"
            disabled={false}
            onSelectedRow={() => setSelectedRow(row)}
            onUnselectedRow={() => setSelectedRow(undefined)}
            row={selectedRow}
            refetch={refetch}
          />,
        ];
      },
    },
  ];

  return (
    <Paper>
      <DataGrid
        rows={proposals}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
      />
    </Paper>
  );
}
