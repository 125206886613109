import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useCallback, useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";

import { FormContainer, TextFieldElement } from "react-hook-form-mui";
import { useContract } from "../../../../../hooks/useContract";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import useMaximumPeriod from "../../../../../hooks/useMaximumPeriod";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

export interface SetMaximumPeriodModalProps {
  open: boolean;
  onClose: () => void;
}

export interface SetMaximumPeriodValues {
  maximumPeriod: number;
}

export default function SetMaximumPeriodModal({
  open,
  onClose,
}: SetMaximumPeriodModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();
  const { data: maximumPeriod, refetch } = useMaximumPeriod();

  const onSubmit = async (data: SetMaximumPeriodValues) => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.setMaximumLockupPeriod(
        Number(data.maximumPeriod) * 31536000
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (/user rejected action/.test(error))
        alert.showAlert(AlertType.ERROR, "User denied transaction signature.");
      else if (/cannot estimate gas/.test(error))
        alert.showAlert(AlertType.ERROR, "Cannot estimate gas.");
      else alert.showAlert(AlertType.ERROR, "Transaction reverted.");

      setStep(Steps.Error);
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2">
              Set Maximum Lockup Period
            </Typography>
            <Typography>
              {`Set maximum lockup  period to allow  ownership release
            date up to.`}
            </Typography>
            <Typography sx={{ mb: 2 }}>
              {`Current value: ${Number(maximumPeriod) / 31536000} years`}
            </Typography>
            <FormContainer onSuccess={onSubmit}>
              <Stack direction="column" spacing={2}>
                <TextFieldElement
                  name={"maximumPeriod"}
                  label="Maximum  Period (years)"
                  type="number"
                  validation={{
                    required: true,
                    validate: (value) => {
                      if (value <= 0) {
                        return "Must greater than zero";
                      }

                      return true;
                    },
                  }}
                  inputProps={{
                    min: 0,
                  }}
                />
                <Button type="submit" variant="contained">
                  Set
                </Button>
              </Stack>
            </FormContainer>
          </Box>
        )}

        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Something went wrong</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
