import Paper from "@mui/material/Paper";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useMediaQuery } from "@mui/material";
import { Role } from "../../../../../apis/__generated__/graphql";
import theme from "../../../../../theme";

export interface KeyKeeperTableProps {
  keyKeepers: Partial<Role>[];
  loading: boolean;
  refetch: () => void;
}

export default function KeyKeeperTable({
  keyKeepers,
  loading,
  refetch,
}: KeyKeeperTableProps) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const columns: GridColDef[] = [
    {
      field: "account",
      headerName: "Address",
      flex: 1,
      valueGetter: ({ value }) => {
        return isSmallScreen
          ? value.slice(0, 10) + "…" + value.slice(-10)
          : value;
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: ({ row }) => [],
      width: 100,
    },
  ];

  return (
    <Paper>
      <DataGrid
        autoHeight
        rows={keyKeepers}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        disableColumnMenu={true}
      />
    </Paper>
  );
}
