import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import AddModal from "../modals/AddModal";

export interface AddButtonProps {
  refetch: () => void;
}

export default function AddButton({ refetch }: AddButtonProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpen}>
        Ownership
      </Button>
      <AddModal open={open} onClose={handleClose} refetch={refetch} />
    </>
  );
}
