import { useContract } from "./useContract";
import { useCallback, useEffect, useState } from "react";

export default function useMaximumPeriod() {
  const contract = useContract();
  const [maximumPeriod, setMaximumPeriod] = useState(0);

  useEffect(() => {
    (async () => {
      const maximumPeriod = await contract.maximumLockupPeriod();
      setMaximumPeriod(Number(maximumPeriod));
    })();
  }, [contract]);

  const refetch = useCallback(async () => {
    const maximumPeriod = await contract.maximumLockupPeriod();
    setMaximumPeriod(Number(maximumPeriod));
  }, [contract]);

  return { data: maximumPeriod, refetch };
}
