import { ProposalStatus } from "../types/proposal";

export enum OwnershipTypes {
  REMOVED,
  PROVISIONAL,
  OWNERSHIP_LOCKUP,
  VESTING_LOCKUP,
  WITHDRAWN,
}

export const EMPTY_DATA = "0x";
export const roles: Record<string, string> = {
  "0xe5a0b4d50f56047f84728557fedbda92f956391bc9d5c762e8461996dd8e7ad7": "admin",
  "0xb19546dff01e856fb3f010c267a7b1c60363cf8a4664e21cc89c26224620214e": "owner",
  "0xec401c6809477145bb117454954d4d8c55d8d425cd27dfee0c1def24cedbd5c5":
    "key keeper",
  "0x241ecf16d79d0f8dbfb92cbc07fe17840425976cf0667f022fe9877caa831b08":
    "manager",
  "0x97667070c54ef182b0f5858b034beac1b6f3089aa2d3188bb1e8929f4fa9b929":
    "operator",
};
export const proposalStatus: Record<string, string> = {
  [ProposalStatus.Approved]: "Approved",
  [ProposalStatus.Canceled]: "Canceled",
  [ProposalStatus.Executed]: "Executed",
  [ProposalStatus.Pending]: "Pending",
};
