import { TokenDistributionManager__factory } from "@gu-corp/joc-token-manager-contracts";
import { proposalStatus, roles } from "./constants";

export function errorDecoder(error: any): string | boolean {
  try {
    if (/user rejected action/.test(error))
      return "User denied transaction signature.";
    else {
      const decodedError =
        TokenDistributionManager__factory.createInterface().parseError(
          error.data
        );
      switch (decodedError?.name) {
        case "AccountAlreadyHadRole":
          return `Account have already ${roles[decodedError.args.at(0)]} role`;
        case "AccessControlUnauthorizedAccount":
          return `Account hasn't ${roles[decodedError.args.at(1)]} role`;
        case "ExceedMaximumExtendPeriod":
          return "Extend time cannot more than a years";
        case "InsufficientBalance":
          return "Insufficient Balance";
        case "InvalidLockupDeadline":
          return "Invalid Lockup Deadline";
        case "InvalidOwnershipLockupStatus":
          return "Invalid Ownership Lockup Status";
        case "InvalidProposalStatus":
          return `Invalid ${
            proposalStatus[decodedError.args.at(0)]
          } Proposal Status`;
        case "LockupDeadlineConfigured":
          return "Lockup Deadline Configured";
        case "LockupDeadlineNotConfigured":
          return "Lockup Deadline Not Configured";
        case "MethodNotAllowed":
          return "Method Not Allowed";
        case "NoneExistedOwnershipForAccount":
          return "None Existed Ownership For Account";
        case "NoneOfAdminOrKeyKeeper":
          return "None Of Admin Or KeyKeeper";
        case "NoneOfAdminOrKeyKeeperOrTokenOwner":
          return "None Of Admin Or KeyKeeper Or Token Owner";
        case "NoneOfAdminOrManager":
          return "None Of Admin Or Manager";
        case "NoneOfAdminOrOperator":
          return "None Of Admin Or Operator";
        case "NoneOfOwnerOrAdmin":
          return "None Of Admin Or Owner";
        case "NoneOfOwnerOrKeyKeeper":
          return "None Of Owner Or KeyKeeper";
        case "NoneReachedLockupDeadline":
          return "None Reached Lockup Deadline";
        case "NoneZeroAddress":
          return "None Zero Address";
        case "NoneZeroAmount":
          return "None Zero Amount";
        case "NoneZeroExtendPeriod":
          return "None Zero Extend Period";
        case "NoneZeroLockupDeadline":
          return "None Zero Lockup Deadline";
        case "NoneZeroNumberOfKeyKeepers":
          return "None Zero Number Of KeyKeepers";
        case "OwnershipForAccountExisted":
          return "Ownership For Account Existed";
        case "ProposalIndexOutOfBound":
          return "Proposal Index Out Of Bound";
        case "UpgradeNotAllowed":
          return "Upgrade Not Allowed";
        default:
          return false;
      }
    }
  } catch (error) {
    return false;
  }
}
