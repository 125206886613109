import { Button } from "@mui/material";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import { useState } from "react";
import SetMaximumExtendPeriodModal from "../modals/SetMaximumExtendPeriodModal";

export default function SetMaximumExtendPeriodButton() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<TransferWithinAStationIcon />}
        variant="contained"
        onClick={handleOpen}
      >
        Set Maximum Extend Period
      </Button>
      <SetMaximumExtendPeriodModal open={open} onClose={handleClose} />
    </>
  );
}
