import { Box, Paper, Stack, Typography } from "@mui/material";
import TransferOwnershipButton from "./components/buttons/TransferOwnershipButton";
import SetMaximumExtendPeriodButton from "./components/buttons/SetMaximumExtendPeriod";
import SetMaximumPeriodButton from "./components/buttons/SetMaximumPeriod";

export default function SettingPage() {
  return (
    <Box>
      <Typography variant="h5" sx={{ flexGrow: 1, marginBottom: 1 }}>
        Settings
      </Typography>

      <Stack spacing={2}>
        <Paper sx={{ padding: 3 }}>
          <Box sx={{ marginBottom: 1 }}>
            <Typography variant="subtitle1">Maximum Lockup Period</Typography>
            <Typography variant="subtitle2">
              Set maximum period to within the limit when configure lock-up
              deadline.
            </Typography>
          </Box>

          <SetMaximumPeriodButton />
        </Paper>

        <Paper sx={{ padding: 3 }}>
          <Box sx={{ marginBottom: 1 }}>
            <Typography variant="subtitle1">
              Maximum Lockup Extend Period
            </Typography>
            <Typography variant="subtitle2">
              Set maximum lockup period to allow extend ownership release date
              up to.
            </Typography>
          </Box>

          <SetMaximumExtendPeriodButton />
        </Paper>

        <Paper sx={{ padding: 3 }}>
          <Box sx={{ marginBottom: 1 }}>
            <Typography variant="subtitle1">Ownership</Typography>
            <Typography variant="subtitle2">
              Transfer Ownership will renounce your ownership permission and
              transfer your selected administrator
            </Typography>
          </Box>

          <TransferOwnershipButton />
        </Paper>
      </Stack>
    </Box>
  );
}
