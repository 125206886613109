import Drawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { Box, useMediaQuery } from "@mui/material";
import { useEffect } from "react";

import { useAccount } from "@gusdk/gu-wallet-connector";
import useRoles from "../../hooks/useRoles";
import theme from "../../theme";
import useAuthorized from "../../hooks/useAuthorized";
import {
  useMobileSidebarState,
  useMobileSidebarVisibleState,
} from "../../providers/mobile-sidebar/MobileSidebarProvider";
import Unauthenticated from "../Layout/UnAuthenticated";
import { Link, Outlet, useLocation } from "react-router-dom";
import Authenticating from "../Layout/Authenticating";

const DRAWER_WIDTH = 240;
const DRAWER_MEDIUM_WIDTH = 200;

const LINKS = [
  {
    text: "Ownerships",
    href: "/operator/ownerships",
    icon: FormatListBulletedIcon,
  },
];

export default function OperatorLayout() {
  const location = useLocation();
  const { OPERATOR_ROLE } = useRoles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { account } = useAccount();
  const { authorized, loading } = useAuthorized(OPERATOR_ROLE, account);

  const { setVisible } = useMobileSidebarVisibleState();
  useEffect(() => {
    if (authorized) {
      setVisible(true);
    }
  }, [authorized, setVisible]);

  const { open: mobileOpen } = useMobileSidebarState();

  if (loading) {
    return <Authenticating />;
  }

  if (!authorized) {
    return <Unauthenticated />;
  }

  return (
    <>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
            top: ["48px", "56px", "64px"],
            height: "auto",
            bottom: 0,
          },
          display: { xs: "block", sm: "none" },
        }}
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton
                component={Link}
                to={href}
                selected={href == location.pathname}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ mt: "auto" }} />
      </Drawer>
      <Drawer
        sx={{
          width: isMediumScreen ? DRAWER_MEDIUM_WIDTH : DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: isMediumScreen ? DRAWER_MEDIUM_WIDTH : DRAWER_WIDTH,
            boxSizing: "border-box",
            top: ["48px", "56px", "64px"],
            height: "auto",
            bottom: 0,
          },
          display: { xs: "none", sm: "block" },
        }}
        variant="permanent"
        anchor="left"
        open={mobileOpen}
      >
        <Divider />
        <List>
          {LINKS.map(({ text, href, icon: Icon }) => (
            <ListItem key={href} disablePadding>
              <ListItemButton
                component={Link}
                to={href}
                selected={href == location.pathname}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ mt: "auto" }} />
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          ml: `${
            isSmallScreen
              ? 0
              : isMediumScreen
              ? DRAWER_MEDIUM_WIDTH
              : DRAWER_WIDTH
          }px`,
          p: [3],
        }}
      >
        <Outlet />
      </Box>
    </>
  );
}
