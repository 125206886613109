import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {
  useAccount,
  useEthBalance,
  useDisconnect,
} from "@gusdk/gu-wallet-connector";

import { formatEther } from "ethers";
import theme from "../../../theme";
import { useChain } from "../../../hooks/useChain";
import { truncateAddress } from "../../../libraries/utils/truncate";

export default function DropdownMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { disconnect } = useDisconnect();
  const { account } = useAccount();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ethBalance = useEthBalance({
    walletAddress: account,
  });
  const chain = useChain();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Button
        id="dropdown-menu"
        aria-controls={open ? "dropdown-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="outlined"
        size="small"
      >
        <Typography variant="body1" fontSize={isSmallScreen ? 12 : 14}>
          {account && truncateAddress(account)}
        </Typography>
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <AccountBalanceWalletIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            Balance: {formatEther(ethBalance)} {chain?.nativeCurrency?.symbol}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => disconnect()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Disconnect</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
