import { useContract } from './useContract';
import { useEffect, useState } from 'react';

export default function useMetadata() {
  const contract = useContract();
  const [metadata, setMetadata] = useState({
    symbol: '',
    decimals: 18,
    name: '',
  });

  useEffect(() => {
    (async () => {
      const symbol = await contract.symbol();
      const name = await contract.name();
      const decimals = await contract.decimals();
      setMetadata({
        name,
        symbol,
        decimals: +decimals.toString(),
      });
    })();
  }, [contract]);

  return metadata;
}
