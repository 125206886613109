import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import DepositModal from "../modals/DepositModal";

export interface AddDepositButtonProps {
  refetch: () => void;
}

export default function DepositButton({ refetch }: AddDepositButtonProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpen}>
        Deposit
      </Button>
      <DepositModal open={open} onClose={handleClose} refetch={refetch} />
    </>
  );
}
