import { GridColDef, DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { TransferTokenOwnershipProposal } from "../../../../../apis/__generated__/graphql";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";

export interface ProceedTableProps {
  proposals: TransferTokenOwnershipProposal[];
  loading: boolean;
}

export default function ProceedTable({
  proposals,
  loading,
}: ProceedTableProps) {
  const columns: GridColDef<TransferTokenOwnershipProposal>[] = [
    {
      field: "index",
      headerName: "Index",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      width: 120,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "newOwner",
      headerName: "New Owner",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
  ];

  return (
    <Paper>
      <DataGrid
        rows={proposals}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
      />
    </Paper>
  );
}
