import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { UpgradeableProposal } from "../../../../../apis/__generated__/graphql";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
import DangerConfirmModal from "../../../../shared/DangerConfirmModal";
import { EMPTY_DATA } from "../../../../../libraries/utils/constants";

export interface ExecuteModalProps {
  row: UpgradeableProposal;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ExecuteModal({
  open,
  onClose,
  row,
  refetch,
}: ExecuteModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const [openConfirm, setOpenConfirm] = useState(false);
  const contract = useContract();

  const onSubmit = async () => {
    setOpenConfirm(true);
  };

  const handleClose = useCallback(() => {
    onClose();

    setStep(Steps.Creating);
  }, [onClose]);

  const handleCloseConfirm = useCallback(() => {
    setOpenConfirm(false);
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      setStep(Steps.Waiting);
      const tx = await contract.upgradeToAndCall(row.id, EMPTY_DATA);
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  }, [alert, contract, refetch, row.id]);

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {step === Steps.Creating && (
            <Box>
              <Typography variant="h6" component="h2">
                Execute Upgrade Proposal
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Execute transaction to upgrade contract to new implementation
              </Typography>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  startIcon={<LockOpenIcon />}
                  variant="contained"
                  onClick={onSubmit}
                >
                  Execute
                </Button>
              </Box>
            </Box>
          )}
          {step === Steps.Waiting && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
            </Box>
          )}

          {step === Steps.Finalizing && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
              <Typography sx={{ mt: 2 }}>
                Waiting for block finalizing
              </Typography>
            </Box>
          )}

          {step === Steps.Error && (
            <Box sx={{ textAlign: "center" }}>
              <ErrorIcon />
              <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
              <Button
                onClick={() => {
                  setStep(Steps.Creating);
                }}
              >
                Retry
              </Button>
            </Box>
          )}

          {step === Steps.Finalized && (
            <Box sx={{ textAlign: "center" }}>
              <DoneIcon />
              <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
            </Box>
          )}
        </Box>
      </Modal>
      <DangerConfirmModal
        open={openConfirm}
        onClose={handleCloseConfirm}
        onConfirm={handleConfirm}
      />
    </>
  );
}
