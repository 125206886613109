import { Chip } from "@mui/material";
import { OwnershipTypes } from "../../libraries/utils/constants";

export default function StatusChip({ status }: { status: OwnershipTypes }) {
  return (
    <Chip
      label={
        status == OwnershipTypes.PROVISIONAL
          ? "PROVISIONAL"
          : status == OwnershipTypes.VESTING_LOCKUP
          ? "VESTING LOCKUP"
          : "OWNERSHIP LOCKUP"
      }
      color={status == OwnershipTypes.PROVISIONAL ? "default" : "primary"}
      size="small"
      sx={{ fontSize: 10 }}
    />
  );
}
