import { Box, Button, Container, Typography } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useSwitchNetwork } from "@gusdk/gu-wallet-connector";
import { chains } from "../../providers/wallet-connector/useClient";
import { env } from "../../libraries/utils/env";

export default function WrongNetwork() {
  const { switchNetwork } = useSwitchNetwork();

  const chainId = +(env.REACT_APP_CHAIN_ID as string);
  const chain = chains.find((chain) => chain.id === chainId);

  return (
    <Container>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "40vh",
        }}
      >
        <Typography variant="h5">{`Wrong Network`}</Typography>
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: 1,
          }}
        >{`Please switch to ${chain?.name}`}</Typography>
        <Button
          variant="contained"
          startIcon={<ChangeCircleIcon />}
          onClick={() => switchNetwork?.(chainId)}
        >
          Switch Network
        </Button>
      </Box>
    </Container>
  );
}
