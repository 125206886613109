import Box from "@mui/material/Box";
import { Grid, Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import OwnershipTable from "./components/tables/OwnershipTable";
import AddOwnershipButton from "./components/buttons/AddButton";
import useMetadata from "../../../hooks/useMetadata";
import { GET_OWNERSHIPS } from "../../../libraries/queries/ownerships";
import { OwnershipTypes } from "../../../libraries/utils/constants";
import useBalances from "../../../hooks/useBalances";
import { formatEther } from "ethers";

export default function OwnershipPage() {
  const { symbol } = useMetadata();
  const {
    data: vestingData,
    refetch: refetchOwnerships,
    loading,
  } = useQuery(GET_OWNERSHIPS, {
    variables: {
      where: {
        status_in: [
          OwnershipTypes.PROVISIONAL,
          OwnershipTypes.OWNERSHIP_LOCKUP,
          OwnershipTypes.VESTING_LOCKUP,
        ],
      },
    },
  });

  const ownerships = vestingData?.ownerships || [];

  const refetch = () => {
    refetchOwnerships();
    refetchBalance();
  };

  const {
    data: { totalSupply, totalLocked, totalProvisional },
    refetch: refetchBalance,
  } = useBalances();

  const totalUnallocated = totalSupply - totalLocked - totalProvisional;

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Ownerships
        </Typography>
        <AddOwnershipButton refetch={refetch} />
      </Stack>
      <Box sx={{ flexGrow: 1, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Box boxShadow={1} padding={1} borderRadius={1} height={"100%"}>
              <Typography variant="subtitle1">Available Amount</Typography>
              <Typography>{`${formatEther(
                totalUnallocated
              )} ${symbol}`}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <OwnershipTable
        ownerships={ownerships}
        refetch={refetch}
        loading={loading}
      />
    </Box>
  );
}
