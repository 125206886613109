import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { GET_CANCEL_LOCKUP_PROPOSALS } from "../../../../libraries/queries/proposals";
import AddButton from "./buttons/AddButton";
import { GET_OWNERSHIPS } from "../../../../libraries/queries/ownerships";
import { OwnershipTypes } from "../../../../libraries/utils/constants";
import { CancelLockupProposal } from "../../../../apis/__generated__/graphql";
import { ProposalStatus } from "../../../../libraries/types/proposal";
import CancelLockupProposalTable from "./tables/CancelLockupProposalTable";
import ProposalTabs from "../../../shared/ProposalTabs";
import ProceedTable from "./tables/ProceedTable";

export default function CancelLockupOwnershipProposalPage() {
  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_CANCEL_LOCKUP_PROPOSALS, {
    variables: {
      where: {
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.cancelLockupProposals ||
    []) as CancelLockupProposal[];

  const { data: vestingData } = useQuery(GET_OWNERSHIPS, {
    variables: {
      where: {
        status_in: [
          OwnershipTypes.VESTING_LOCKUP,
          OwnershipTypes.OWNERSHIP_LOCKUP,
        ],
      },
    },
  });

  const ownerships = vestingData?.ownerships || [];

  const { data: proceedData, loading: proceedLoading } = useQuery(
    GET_CANCEL_LOCKUP_PROPOSALS,
    {
      variables: {
        where: {
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );
  const proceedProposals = (proceedData?.cancelLockupProposals ||
    []) as CancelLockupProposal[];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Cancel Lockup Proposal
        </Typography>
        <AddButton refetch={refetch} ownerships={ownerships} />
      </Stack>
      <ProposalTabs
        processingTab={
          <CancelLockupProposalTable
            proposals={proposals}
            loading={loading}
            refetch={refetch}
          />
        }
        proceedTab={
          <ProceedTable loading={proceedLoading} proposals={proceedProposals} />
        }
      />
    </Box>
  );
}
