import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import GrantButton from "./components/buttons/GrantButton";
import ManagerTable from "./components/tables/ManagerTable";
import useRoles from "../../../hooks/useRoles";
import { GET_ROLES } from "../../../libraries/queries/roles";

export default function ManagerPage() {
  const { MANAGER_ROLE } = useRoles();

  const { data, refetch, loading } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: MANAGER_ROLE,
      },
    },
    skip: !MANAGER_ROLE,
  });

  const managers = data?.roles || [];

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Managers
        </Typography>
        <GrantButton refetch={refetch} />
      </Stack>

      <ManagerTable managers={managers} refetch={refetch} loading={loading} />
    </Box>
  );
}
