import { gql } from "../../apis/__generated__";

export const GET_CANCEL_LOCKUP_PROPOSALS = gql(/* GraphQL */ `
  query cancelLockupProposals($where: CancelLockupProposal_filter) {
    cancelLockupProposals(where: $where) {
      id
      owner
      index
      administratorApproved
      keyKeeperApproved
      tokenOwnerApproved
      status
    }
  }
`);

export const GET_EXTEND_LOCKUP_PERIOD_PROPOSALS = gql(/* GraphQL */ `
  query extendLockupPeriodProposals($where: ExtendLockupPeriodProposal_filter) {
    extendLockupPeriodProposals(where: $where) {
      id
      owner
      index
      extensionPeriod
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_GRANT_KEY_KEEPER_PROPOSALS = gql(/* GraphQL */ `
  query grantKeyKeeperProposals($where: GrantKeyKeeperProposal_filter) {
    grantKeyKeeperProposals(where: $where) {
      id
      newKeyKeeper
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_REVOKE_KEY_KEEPER_PROPOSALS = gql(/* GraphQL */ `
  query revokeKeyKeeperProposals($where: RevokeKeyKeeperProposal_filter) {
    revokeKeyKeeperProposals(where: $where) {
      id
      keyKeeper
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_TRANSFER_OWNERSHIP_PROPOSALS = gql(/* GraphQL */ `
  query transferOwnershipProposals($where: TransferOwnershipProposal_filter) {
    transferOwnershipProposals(where: $where) {
      id
      newOwner
      administratorApproved
      keyKeeperApproved
      status
    }
  }
`);

export const GET_TRANSFER_TOKEN_OWNERSHIP_PROPOSALS = gql(/* GraphQL */ `
  query transferTokenOwnershipProposals(
    $where: TransferTokenOwnershipProposal_filter
  ) {
    transferTokenOwnershipProposals(where: $where) {
      id
      owner
      index
      newOwner
      status
    }
  }
`);

export const GET_UPGRADABLE_PROPOSALS = gql(/* GraphQL */ `
  query upgradeableProposals($where: UpgradeableProposal_filter) {
    upgradeableProposals(where: $where) {
      id
      newImplementation
      ownerApproved
      keyKeeperApproved
      status
    }
  }
`);
