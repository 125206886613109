import { Button } from '@mui/material';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { useState } from 'react';
import TransferOwnershipModal from '../modals/TransferOwnershipModal';

export default function TransferOwnershipButton() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<TransferWithinAStationIcon />}
        variant='contained'
        color='error'
        onClick={handleOpen}
      >
        Transfer Ownership
      </Button>
      <TransferOwnershipModal open={open} onClose={handleClose} />
    </>
  );
}
