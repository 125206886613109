import useIsInTheWrongNetwork from "../../hooks/useIsInTheWrongNetwork";
import Unconnected from "./Unconnected";
import WrongNetwork from "./WrongNetwork";
import { useStatus } from "@gusdk/gu-wallet-connector";

export default function ContentLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const status = useStatus();
  const isInTheWrongNetwork = useIsInTheWrongNetwork();
  const isConnected = status === "connected";

  if (!isConnected) {
    return <Unconnected />;
  }

  if (isInTheWrongNetwork) {
    return <WrongNetwork />;
  }

  return <>{children}</>;
}
