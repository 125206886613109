import { Chip } from "@mui/material";
import { ProposalStatus } from "../../libraries/types/proposal";

export default function ProposalStatusChip({
  status,
}: {
  status: ProposalStatus;
}) {
  return (
    <Chip
      label={
        status == ProposalStatus.Pending
          ? "Pending"
          : status == ProposalStatus.Canceled
          ? "Canceled"
          : status == ProposalStatus.Approved
          ? "Approved"
          : "Executed"
      }
      color={
        status == ProposalStatus.Pending
          ? "warning"
          : status == ProposalStatus.Canceled
          ? "default"
          : status == ProposalStatus.Approved
          ? "primary"
          : "success"
      }
      size="small"
      sx={{ fontSize: 10 }}
    />
  );
}
