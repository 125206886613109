import { Box, Button, Container, Stack, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useState } from "react";
import { WalletConnectorDialog } from "@gusdk/gu-wallet-connector";

export default function Unconnected() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        minHeight="calc(100vh - 64px)"
      >
        <Stack spacing={1}>
          <Box>
            <AccountBalanceWalletIcon fontSize="large" />
            <Typography variant="h5">{`Connect Wallet`}</Typography>
            <Typography>{`Your wallet doesn't connect to website`}</Typography>
          </Box>

          <Button onClick={handleClickOpen} variant="contained">
            Connect
          </Button>
          <WalletConnectorDialog open={open} onClose={handleClose} />
        </Stack>
      </Box>
    </Container>
  );
}
