import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import {
  AutocompleteElement,
  DateTimePickerElement,
  FormContainer,
  useWatch,
} from "react-hook-form-mui";
import { Stack } from "@mui/material";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { Ownership } from "../../../../../apis/__generated__/graphql";
import dayjs from "dayjs";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";
import useMaximumExtendPeriod from "../../../../../hooks/useMaximumExtendPeriod";

export interface AddModalProps {
  ownerships: Ownership[];
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface AddValues {
  selectedOwnership: {
    id: string;
  };
  deadline: dayjs.Dayjs;
}

export default function AddModal({
  open,
  ownerships,
  onClose,
  refetch,
}: AddModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();

  const handleClose = useCallback(() => {
    onClose();
    setStep(Steps.Creating);
  }, [onClose]);

  const onSubmit = async (data: AddValues) => {
    try {
      setStep(Steps.Waiting);
      const ownership = ownerships.find(
        (ownership) => ownership.id === data?.selectedOwnership?.id
      );

      const extension = dayjs
        .duration(
          dayjs(data.deadline).unix() -
            dayjs.unix(ownership?.lockupDeadline).unix(),
          "seconds"
        )
        .asSeconds();

      const tx = await contract.createExtendLockupPeriodProposal(
        ownership?.owner,
        ownership?.index,
        extension
      );
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  const options = ownerships.map((ownership) => ({
    id: ownership.id,
    label: `${ownership.owner} - #${ownership.index}`,
  }));

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <FormContainer onSuccess={onSubmit}>
              <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>
                Create Extend Lockup Period Proposal
              </Typography>
              <Box>
                <Form
                  options={options}
                  onSubmit={onSubmit}
                  ownerships={ownerships}
                />
              </Box>
            </FormContainer>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

function Form({
  onSubmit,
  options,
  ownerships,
}: {
  onSubmit: (data: AddValues) => void;
  options: { id: string; label: string }[];
  ownerships: Ownership[];
}) {
  const { data: maximumExtendPeriod } = useMaximumExtendPeriod();
  const [selectedOwnership] = useWatch({
    name: ["selectedOwnership"],
  });

  const ownership = ownerships.find(
    (ownership) => ownership.id === selectedOwnership?.id
  );

  return (
    <Stack direction="column" spacing={2}>
      <AutocompleteElement
        options={options}
        name={"selectedOwnership"}
        label="Ownership"
        required={true}
      />
      <DateTimePickerElement
        name="deadline"
        label="New Deadline"
        disablePast
        disabled={!ownership}
        validation={{
          required: true,
        }}
        minDateTime={dayjs.unix(ownership?.lockupDeadline)}
        maxDateTime={dayjs().add(maximumExtendPeriod, "seconds")}
        ampm={false}
      />
      <Typography>
        Current deadline:{" "}
        {!ownership
          ? "---"
          : ownership?.lockupDeadline == 0
          ? "NOT SET"
          : dayjs.unix(ownership?.lockupDeadline).format("MM/DD/YYYY, hh:mm A")}
      </Typography>
      <Button type={"submit"} startIcon={<AddIcon />} variant="contained">
        Add
      </Button>
    </Stack>
  );
}
