import { useContract } from "./useContract";
import { useEffect, useState } from "react";

export default function useRoles() {
  const contract = useContract();
  const [roles, setRoles] = useState({
    ADMINISTRATOR_ROLE: "",
    KEY_KEEPER_ROLE: "",
    MANAGER_ROLE: "",
    OPERATOR_ROLE: "",
    OWNER_ROLE: "",
  });

  useEffect(() => {
    (async () => {
      const ADMINISTRATOR_ROLE = await contract.ADMINISTRATOR_ROLE();
      const KEY_KEEPER_ROLE = await contract.KEY_KEEPER_ROLE();
      const MANAGER_ROLE = await contract.MANAGER_ROLE();
      const OPERATOR_ROLE = await contract.OPERATOR_ROLE();
      const OWNER_ROLE = await contract.OWNER_ROLE();

      setRoles({
        ADMINISTRATOR_ROLE,
        KEY_KEEPER_ROLE,
        MANAGER_ROLE,
        OPERATOR_ROLE,
        OWNER_ROLE,
      });
    })();
  }, [contract]);

  return roles;
}
