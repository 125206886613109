import { useContract } from "./useContract";
import { useEffect, useState } from "react";

export default function useVersion() {
  const contract = useContract();
  const [version, setVersion] = useState("");

  useEffect(() => {
    (async () => {
      const version = await contract.VERSION();
      setVersion(version);
    })();
  }, [contract]);

  return version;
}
