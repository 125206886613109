import { gql } from "../../apis/__generated__";

export const GET_OWNERSHIPS = gql(/* GraphQL */ `
  query ownerships($first: Int, $skip: Int, $where: Ownership_filter) {
    ownerships(first: $first, skip: $skip, where: $where) {
      id
      owner
      index
      amount
      lockupDeadline
      status
    }
  }
`);
