import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertProvider } from "./alerts/AlertProvider";
import WalletConnectProvider from "./wallet-connector/WalletConnectProvider";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { MobileSidebarProvider } from "./mobile-sidebar/MobileSidebarProvider";
import { env } from "../libraries/utils/env";

const client = new ApolloClient({
  uri: env.REACT_APP_SUBGRAPH_GRAPHQL,
  cache: new InMemoryCache(),
});

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <SnackbarProvider>
          <WalletConnectProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AlertProvider>
                <MobileSidebarProvider>{children}</MobileSidebarProvider>
              </AlertProvider>
            </LocalizationProvider>
          </WalletConnectProvider>
        </SnackbarProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default Providers;
