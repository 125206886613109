import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import KeyKeeperTable from "./components/tables/KeyKeeperTable";
import { GET_ROLES } from "../../../libraries/queries/roles";
import useRoles from "../../../hooks/useRoles";

export default function KeyKeeperPage() {
  const { KEY_KEEPER_ROLE } = useRoles();

  const { data, refetch, loading } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: KEY_KEEPER_ROLE,
      },
    },
    skip: !KEY_KEEPER_ROLE,
  });

  const keyKeepers = data?.roles || [];

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          KeyKeepers
        </Typography>
      </Stack>

      <KeyKeeperTable
        keyKeepers={keyKeepers}
        refetch={refetch}
        loading={loading}
      />
    </Box>
  );
}
