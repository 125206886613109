import * as React from "react";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import Providers from "../providers/providers";
import AppBar from "../components/Layout/AppBar/AppBar";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import ContentLayout from "../components/Layout/ContentLayout";

export default function RootLayout() {
  dayjs.extend(duration);

  return (
    <Providers>
      <AppBar />
      <ContentLayout>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "background.default",
            mt: ["48px", "56px", "64px"],
            p: 3,
          }}
        >
          <Outlet />
        </Box>
      </ContentLayout>
    </Providers>
  );
}
