import { GUWalletConnectorProvider } from '@gusdk/gu-wallet-connector';
import { useClient } from './useClient';

const WalletConnectProvider = ({ children }: { children: React.ReactNode }) => {
  const client = useClient();
  return (
    <GUWalletConnectorProvider client={client}>
      {children}
    </GUWalletConnectorProvider>
  );
};

export default WalletConnectProvider;
