import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { useState } from "react";
import { Chip, Stack, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { WalletConnectorDialog, useStatus } from "@gusdk/gu-wallet-connector";
import DropdownMenu from "./DropdownMenu";
import { useChain } from "../../../hooks/useChain";
import useIsInTheWrongNetwork from "../../../hooks/useIsInTheWrongNetwork";
import theme from "../../../theme";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useMobileSidebarState,
  useMobileSidebarVisibleState,
} from "../../../providers/mobile-sidebar/MobileSidebarProvider";

export default function AppBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const status = useStatus();
  const chain = useChain();
  const pathname = location.pathname;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isInTheWrongNetwork = useIsInTheWrongNetwork();

  const appBarLogo = isSmallScreen ? "JVT" : "JOC Token Ownership";

  const appBarTitle =
    pathname.split("/")?.[1] == "owner"
      ? `${appBarLogo} | Owner`
      : pathname.split("/")?.[1] == "administrator"
      ? `${appBarLogo} | Administrator`
      : pathname.split("/")?.[1] == "manager"
      ? `${appBarLogo} | Manager`
      : pathname.split("/")?.[1] == "operator"
      ? `${appBarLogo} | Operator`
      : pathname.split("/")?.[1] == "key-keeper"
      ? `${appBarLogo} | Key Keeper`
      : pathname.split("/")?.[1] == "senior-manager"
      ? `${appBarLogo} | Senior Manager`
      : appBarLogo;

  const { visible: isMobileSidebarVisible } = useMobileSidebarVisibleState();
  const { onToggle: onMobileSidebarToggle } = useMobileSidebarState();
  const isConnected = status === "connected";

  return (
    <MuiAppBar position="fixed" sx={{ zIndex: 1300 }}>
      <Toolbar sx={{ backgroundColor: "background.paper" }}>
        {isConnected && !isInTheWrongNetwork && isMobileSidebarVisible && (
          <IconButton
            edge="start"
            sx={{ mr: 2, display: { sm: "none" } }}
            onClick={onMobileSidebarToggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          noWrap
          component="div"
          color="black"
          sx={{ flexGrow: 1, cursor: "pointer" }}
          fontSize={isSmallScreen ? 16 : 20}
          onClick={() => navigate("/")}
        >
          {appBarTitle}
        </Typography>
        {isConnected ? (
          <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
            <Chip label={isSmallScreen ? chain?.id : chain?.name} />
            <DropdownMenu />
          </Stack>
        ) : (
          <IconButton
            size="large"
            edge="start"
            sx={{ mr: 2 }}
            onClick={handleClickOpen}
          >
            <AccountBalanceWalletIcon />
          </IconButton>
        )}
        <WalletConnectorDialog open={open} onClose={handleClose} />
      </Toolbar>
    </MuiAppBar>
  );
}
