import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export interface ProposalTabsProps {
  processingTab: React.ReactNode;
  proceedTab: React.ReactNode;
}

export default function ProposalTabs({
  proceedTab,
  processingTab,
}: ProposalTabsProps) {
  const [value, setValue] = React.useState("PROCESSING");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Processing" value="PROCESSING" />
            <Tab label="Proceed" value="PROCEED" />
          </TabList>
        </Box>
        <TabPanel value="PROCESSING">{processingTab}</TabPanel>
        <TabPanel value="PROCEED">{proceedTab}</TabPanel>
      </TabContext>
    </Box>
  );
}
