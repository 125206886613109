import { Tooltip } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";

import { useState } from "react";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import ExecuteModal from "../modals/ExecuteModal";
import { UpgradeableProposal } from "../../../../../apis/__generated__/graphql";

export interface ExecuteButtonProps {
  refetch: () => void;
  row?: UpgradeableProposal;
  disabled: boolean;
  onSelectedRow: () => void;
  onUnselectedRow: () => void;
}

export default function ExecuteButton({
  refetch,
  row,
  disabled,
  onSelectedRow,
  onUnselectedRow,
}: ExecuteButtonProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    onSelectedRow();
  };

  const handleClose = () => {
    setOpen(false);
    onUnselectedRow();
  };

  return (
    <>
      <GridActionsCellItem
        label="Execute"
        icon={
          <Tooltip title="Execute">
            <FlashOnIcon />
          </Tooltip>
        }
        onClick={handleOpen}
        disabled={disabled}
      />
      {row && (
        <ExecuteModal
          row={row}
          open={open}
          onClose={handleClose}
          refetch={refetch}
        />
      )}
    </>
  );
}
