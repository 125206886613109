import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import GrantButton from "./components/buttons/GrantButton";
import AdminTable from "./components/tables/AdminTable";
import useRoles from "../../../hooks/useRoles";
import { GET_ROLES } from "../../../libraries/queries/roles";

export default function AdminPage() {
  const { ADMINISTRATOR_ROLE } = useRoles();

  const { data, refetch, loading } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: ADMINISTRATOR_ROLE,
      },
    },
    skip: !ADMINISTRATOR_ROLE,
  });

  const admins = data?.roles || [];

  return (
    <Box>
      <Stack direction="row" sx={{ width: "100%", marginBottom: 2 }}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Administrators
        </Typography>
        <GrantButton refetch={refetch} />
      </Stack>

      <AdminTable admins={admins} refetch={refetch} loading={loading} />
    </Box>
  );
}
