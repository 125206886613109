import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function HomePage() {
  return (
    <Box sx={{ flexGrow: 1, width: "80%", margin: "auto" }}>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Select Your Role
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Owner
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={"/owner/admins"}>
                <Button size="small" variant="contained">
                  Access
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Administrator
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={"/administrator/managers"}>
                <Button size="small" variant="contained">
                  Access
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Manager
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={"/manager/operators"}>
                <Button size="small" variant="contained">
                  Access
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Operator
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={"/operator/ownerships"}>
                <Button size="small" variant="contained">
                  Access
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card
            sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Key Keeper
              </Typography>
            </CardContent>
            <CardActions sx={{ marginBottom: "auto" }}>
              <Link to={"/key-keeper/ownerships"}>
                <Button size="small" variant="contained">
                  Access
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
