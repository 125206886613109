import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import TransferOwnershipProposalTable from "./tables/TransferOwnershipProposalTable";
import { GET_TRANSFER_OWNERSHIP_PROPOSALS } from "../../../../libraries/queries/proposals";
import {
  Role,
  TransferOwnershipProposal,
} from "../../../../apis/__generated__/graphql";
import AddButton from "./buttons/AddButton";
import { GET_ROLES } from "../../../../libraries/queries/roles";
import useRoles from "../../../../hooks/useRoles";
import { ProposalStatus } from "../../../../libraries/types/proposal";
import ProposalTabs from "../../../shared/ProposalTabs";
import ProceedTable from "./tables/ProceedTable";

export default function TransferOwnershipProposalPage() {
  const { OWNER_ROLE, ADMINISTRATOR_ROLE } = useRoles();

  const {
    data: proposalData,
    refetch,
    loading,
  } = useQuery(GET_TRANSFER_OWNERSHIP_PROPOSALS, {
    variables: {
      where: {
        status: ProposalStatus.Pending,
      },
    },
  });

  const proposals = (proposalData?.transferOwnershipProposals ||
    []) as TransferOwnershipProposal[];

  const { data: ownerData } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: OWNER_ROLE,
      },
    },
    skip: !OWNER_ROLE,
  });

  const currentOwner = ownerData?.roles?.[0] as Role;

  const { data } = useQuery(GET_ROLES, {
    variables: {
      where: {
        role: ADMINISTRATOR_ROLE,
      },
    },
    skip: !ADMINISTRATOR_ROLE,
  });
  const admins = (data?.roles || []) as Role[];

  const { data: proceedData, loading: proceedLoading } = useQuery(
    GET_TRANSFER_OWNERSHIP_PROPOSALS,
    {
      variables: {
        where: {
          status_not: ProposalStatus.Pending,
        },
      },
    }
  );

  const proceedProposals = (proceedData?.transferOwnershipProposals ||
    []) as TransferOwnershipProposal[];

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Transfer Ownership Proposal
        </Typography>
        <AddButton
          refetch={refetch}
          admins={admins}
          currentOwner={currentOwner}
        />
      </Stack>
      <ProposalTabs
        processingTab={
          <TransferOwnershipProposalTable
            proposals={proposals}
            loading={loading}
            refetch={refetch}
          />
        }
        proceedTab={
          <ProceedTable loading={proceedLoading} proposals={proceedProposals} />
        }
      />
    </Box>
  );
}
