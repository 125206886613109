import { GridColDef, DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { Stack, Typography } from "@mui/material";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import DoneIcon from "@mui/icons-material/Done";
import { UpgradeableProposal } from "../../../../../apis/__generated__/graphql";
import ProposalStatusChip from "../../../../shared/ProposalStatusChip";

export interface ProceedTableProps {
  proposals: UpgradeableProposal[];
  loading: boolean;
}

export default function ProceedTable({
  proposals,
  loading,
}: ProceedTableProps) {
  const columns: GridColDef<UpgradeableProposal>[] = [
    {
      field: "newImplementation",
      headerName: "New Implementation",
      flex: 0.3,
      minWidth: 120,
    },
    {
      field: "confirms",
      headerName: "Confirms",
      width: 150,
      renderCell: ({ row }) => {
        const numberOfConfirmations =
          (row.ownerApproved ? 1 : 0) + (row.keyKeeperApproved ? 1 : 0);

        return `${numberOfConfirmations}/2`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      renderCell: ({ value }) => <ProposalStatusChip status={value} />,
    },
    {
      field: "confirmations",
      headerName: "Confirmations",
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography fontSize={14} display="flex">
              Owner:{" "}
              {row.ownerApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
            <Typography fontSize={14} display="flex">
              Key Keeper:{" "}
              {row.keyKeeperApproved ? (
                <DoneIcon fontSize="small" />
              ) : (
                <HourglassTopIcon fontSize="small" />
              )}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  return (
    <Paper>
      <DataGrid
        rows={proposals}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        disableColumnMenu={true}
        rowHeight={100}
      />
    </Paper>
  );
}
