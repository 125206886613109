import { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Ownership } from "../../../../../apis/__generated__/graphql";
import {
  AlertType,
  useAlert,
} from "../../../../../providers/alerts/AlertProvider";
import { useContract } from "../../../../../hooks/useContract";
import { FormContainer, SelectElement } from "react-hook-form-mui";
import { Stack } from "@mui/material";
import { errorDecoder } from "../../../../../libraries/utils/error-decoder";

export interface ApproveModalProps {
  row: Ownership;
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

enum Steps {
  Creating,
  Waiting,
  Finalizing,
  Finalized,
  Error,
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface ApprovalValues {
  type: "VESTING_LOCKUP" | "OWNERSHIP_LOCKUP";
}

export default function ApproveModal({
  open,
  onClose,
  row,
  refetch,
}: ApproveModalProps) {
  const alert = useAlert();
  const [step, setStep] = useState<Steps>(Steps.Creating);
  const contract = useContract();

  const onSubmit = async (data: ApprovalValues) => {
    try {
      setStep(Steps.Waiting);
      const isVesting = data.type === "VESTING_LOCKUP";
      const tx = await contract.setLockup(row.owner, row.index, isVesting);
      setStep(Steps.Finalizing);
      await tx.wait();
      setStep(Steps.Finalized);
      refetch();
    } catch (error: any) {
      console.log(error);
      if (errorDecoder(error)) {
        alert.showAlert(AlertType.ERROR, errorDecoder(error).toString());
      } else {
        alert.showAlert(AlertType.ERROR, "Transaction reverted.");
      }
      setStep(Steps.Error);
    }
  };

  const handleClose = useCallback(() => {
    onClose();

    setStep(Steps.Creating);
  }, [onClose]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {step === Steps.Creating && (
          <Box>
            <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
              Approve Provisional Ownership
            </Typography>
            <FormContainer
              onSuccess={onSubmit}
              defaultValues={{ type: "OWNERSHIP_LOCKUP" }}
            >
              <Stack direction="column" spacing={2}>
                <SelectElement
                  fullWidth
                  name={"type"}
                  label="Type"
                  validation={{
                    required: true,
                  }}
                  options={[
                    {
                      id: "VESTING_LOCKUP",
                      label: "VESTING_LOCKUP",
                    },
                    {
                      id: "OWNERSHIP_LOCKUP",
                      label: "OWNERSHIP_LOCKUP",
                    },
                  ]}
                />
                <Button
                  type={"submit"}
                  startIcon={<LockOpenIcon />}
                  variant="contained"
                >
                  Approval
                </Button>
              </Stack>
            </FormContainer>
          </Box>
        )}
        {step === Steps.Waiting && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for approving</Typography>
          </Box>
        )}

        {step === Steps.Finalizing && (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
            <Typography sx={{ mt: 2 }}>Waiting for block finalizing</Typography>
          </Box>
        )}

        {step === Steps.Error && (
          <Box sx={{ textAlign: "center" }}>
            <ErrorIcon />
            <Typography sx={{ mt: 2 }}>Transaction reverted</Typography>
            <Button
              onClick={() => {
                setStep(Steps.Creating);
              }}
            >
              Retry
            </Button>
          </Box>
        )}

        {step === Steps.Finalized && (
          <Box sx={{ textAlign: "center" }}>
            <DoneIcon />
            <Typography sx={{ mt: 2 }}>Block Finalized</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
}
