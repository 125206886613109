import { Chain } from '../types'

export const mainnet: Chain = {
  id: 1,
  name: 'Ethereum Mainnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: ['https://mainnet.infura.io/v3/55868eab18114765a4e6ace5e6353599'],
  blockExplorerUrls: ['https://etherscan.io']
}

export const joc: Chain = {
  id: 81,
  name: 'Japan Open Chain Mainnet',
  nativeCurrency: { name: 'Japan Open Chain Token', symbol: 'JOC', decimals: 18 },
  rpcUrls: ['https://rpc-1.japanopenchain.org:8545', 'https://rpc-2.japanopenchain.org:8545'],
  blockExplorerUrls: ['https://mainnet.japanopenchain.org/']
}

export const gusandbox: Chain = {
  id: 99999,
  name: 'GU Sandbox Chain',
  nativeCurrency: { name: 'GEther', symbol: 'GTH', decimals: 18 },
  rpcUrls: ['https://sandbox1.japanopenchain.org:8545/'],
  blockExplorerUrls: ['https://sandbox1.japanopenchain.org/']
}

export const jocTestnet: Chain = {
  id: 10081,
  name: 'Japan Open Chain Testnet',
  nativeCurrency: { name: 'Japan Open Chain Testnet Token', symbol: 'JOCT', decimals: 18 },
  rpcUrls: ['https://rpc-1.testnet.japanopenchain.org:8545', 'https://rpc-2.testnet.japanopenchain.org:8545'],
  blockExplorerUrls: ['https://explorer.testnet.japanopenchain.org/']
}

export const defaultChains: Chain[] = [mainnet, joc, gusandbox, jocTestnet]
