import { useCallback, useEffect, useState } from "react";
import { useContract } from "./useContract";

export default function useBalances() {
  const contract = useContract();
  const [balances, setBalances] = useState({
    totalSupply: BigInt(0),
    totalLocked: BigInt(0),
    totalProvisional: BigInt(0),
  });

  useEffect(() => {
    (async () => {
      const totalSupply = await contract.totalSupply();
      const totalLocked = await contract.totalLocked();
      const totalProvisional = await contract.totalProvisional();

      setBalances({
        totalSupply: BigInt(totalSupply),
        totalLocked: BigInt(totalLocked),
        totalProvisional: BigInt(totalProvisional),
      });
    })();
  }, [contract]);

  const refetch = useCallback(async () => {
    const totalSupply = await contract.totalSupply();
    const totalLocked = await contract.totalLocked();
    const totalProvisional = await contract.totalProvisional();

    setBalances({
      totalSupply: BigInt(totalSupply),
      totalLocked: BigInt(totalLocked),
      totalProvisional: BigInt(totalProvisional),
    });
  }, [contract]);

  return { data: balances, refetch };
}
