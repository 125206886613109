import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import AddModal from "../modals/AddModal";
import { Role } from "../../../../../apis/__generated__/graphql";

export interface AddButtonProps {
  currentOwner?: Role;
  admins: Role[];
  refetch: () => void;
}

export default function AddButton({
  currentOwner,
  admins,
  refetch,
}: AddButtonProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button startIcon={<AddIcon />} variant="contained" onClick={handleOpen}>
        Proposal
      </Button>
      <AddModal
        open={open}
        onClose={handleClose}
        currentOwner={currentOwner}
        admins={admins}
        refetch={refetch}
      />
    </>
  );
}
