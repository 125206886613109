import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import OwnershipTable from "./components/tables/OwnershipTable";
import { GET_OWNERSHIPS } from "../../../libraries/queries/ownerships";
import { OwnershipTypes } from "../../../libraries/utils/constants";

export default function OwnershipPage() {
  const {
    data: vestingData,
    refetch: refetchOwnerships,
    loading,
  } = useQuery(GET_OWNERSHIPS, {
    variables: {
      where: {
        status_in: [
          OwnershipTypes.VESTING_LOCKUP,
          OwnershipTypes.OWNERSHIP_LOCKUP,
          OwnershipTypes.PROVISIONAL,
        ],
      },
    },
  });

  const ownerships = vestingData?.ownerships || [];

  const refetch = () => {
    refetchOwnerships();
  };

  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Ownerships
        </Typography>
      </Stack>
      <OwnershipTable
        ownerships={ownerships}
        refetch={refetch}
        loading={loading}
      />
    </Box>
  );
}
