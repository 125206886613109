import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./routes/root";
import ErrorPage from "./error-page";
import AboutPage from "./routes/about";
import HomePage from "./routes/home";

import OwnerLayout from "./components/owner/page";
import OwnerAdminPage from "./components/owner/admins/page";
import OwnerBalancePage from "./components/owner/balances/page";
import OwnerSettingPage from "./components/owner/settings/page";
import OwnerUpgradeProposalPage from "./components/owner/proposals/upgrade/page";

import OperatorLayout from "./components/operator/page";
import OperatorOwnershipPage from "./components/operator/ownerships/page";

import KeyKeeperLayout from "./components/key-keeper/page";
import KeyKeeperOwnershipPage from "./components/key-keeper/ownerships/page";
import KeyKeeperKeyKeeperPage from "./components/key-keeper/key-keepers/page";
import KeyKeeperTransferOwnershipProposalPage from "./components/key-keeper/proposals/transfer-ownership/page";
import KeyKeeperRevokeKeyKeeperProposalPage from "./components/key-keeper/proposals/revoke-key-keeper/page";
import KeyKeeperGrantKeyKeeperProposalPage from "./components/key-keeper/proposals/grant-key-keeper/page";
import KeyKeeperCancelLockupProposalPage from "./components/key-keeper/proposals/cancel-lockup/page";
import KeyKeeperExtendLockupPeriodProposalPage from "./components/key-keeper/proposals/extend-lockup-period/page";
import KeyKeeperUpgradeProposalPage from "./components/key-keeper/proposals/upgrade/page";

import AdministratorLayout from "./components/administrator/page";
import AdministratorBalancePage from "./components/administrator/balances/page";
import AdministratorKeyKeeperPage from "./components/administrator/key-keepers/page";
import AdministratorManagerPage from "./components/administrator/managers/page";
import AdministratorOperatorPage from "./components/administrator/operators/page";
import AdministratorOwnershipPage from "./components/administrator/ownerships/page";
import AdministratorTransferOwnershipProposalPage from "./components/administrator/proposals/transfer-ownership/page";
import AdministratorRevokeKeyKeeperProposalPage from "./components/administrator/proposals/revoke-key-keeper/page";
import AdministratorGrantKeyKeeperProposalPage from "./components/administrator/proposals/grant-key-keeper/page";
import AdministratorCancelLockupProposalPage from "./components/administrator/proposals/cancel-lockup/page";
import AdministratorTransferTokenOwnershipProposalPage from "./components/administrator/proposals/transfer-token-ownership/page";
import AdministratorExtendLockupPeriodProposalPage from "./components/administrator/proposals/extend-lockup-period/page";

import ManagerLayout from "./components/manager/page";
import ManagerOperatorPage from "./components/manager/operators/page";

import { generateLicense, LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: "1",
    licensingModel: "subscription",
    scope: "premium",
  })
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/owner",
        element: <OwnerLayout />,
        children: [
          {
            path: "/owner/admins",
            element: <OwnerAdminPage />,
          },
          {
            path: "/owner/balances",
            element: <OwnerBalancePage />,
          },
          {
            path: "/owner/settings",
            element: <OwnerSettingPage />,
          },
          {
            path: "/owner/proposals/upgradable",
            element: <OwnerUpgradeProposalPage />,
          },
        ],
      },
      {
        path: "/operator",
        element: <OperatorLayout />,
        children: [
          {
            path: "/operator/ownerships",
            element: <OperatorOwnershipPage />,
          },
        ],
      },
      {
        path: "/key-keeper",
        element: <KeyKeeperLayout />,
        children: [
          {
            path: "/key-keeper/ownerships",
            element: <KeyKeeperOwnershipPage />,
          },
          {
            path: "/key-keeper/key-keepers",
            element: <KeyKeeperKeyKeeperPage />,
          },
          {
            path: "/key-keeper/proposals/cancel-lockup",
            element: <KeyKeeperCancelLockupProposalPage />,
          },
          {
            path: "/key-keeper/proposals/extend-lockup-period",
            element: <KeyKeeperExtendLockupPeriodProposalPage />,
          },
          {
            path: "/key-keeper/proposals/transfer-ownership",
            element: <KeyKeeperTransferOwnershipProposalPage />,
          },
          {
            path: "/key-keeper/proposals/revoke-key-keeper",
            element: <KeyKeeperRevokeKeyKeeperProposalPage />,
          },
          {
            path: "/key-keeper/proposals/grant-key-keeper",
            element: <KeyKeeperGrantKeyKeeperProposalPage />,
          },
          {
            path: "/key-keeper/proposals/upgradable",
            element: <KeyKeeperUpgradeProposalPage />,
          },
        ],
      },
      {
        path: "/administrator",
        element: <AdministratorLayout />,
        children: [
          {
            path: "/administrator/balances",
            element: <AdministratorBalancePage />,
          },
          {
            path: "/administrator/key-keepers",
            element: <AdministratorKeyKeeperPage />,
          },
          {
            path: "/administrator/managers",
            element: <AdministratorManagerPage />,
          },
          {
            path: "/administrator/operators",
            element: <AdministratorOperatorPage />,
          },
          {
            path: "/administrator/ownerships",
            element: <AdministratorOwnershipPage />,
          },
          {
            path: "/administrator/proposals/transfer-ownership",
            element: <AdministratorTransferOwnershipProposalPage />,
          },
          {
            path: "/administrator/proposals/revoke-key-keeper",
            element: <AdministratorRevokeKeyKeeperProposalPage />,
          },
          {
            path: "/administrator/proposals/grant-key-keeper",
            element: <AdministratorGrantKeyKeeperProposalPage />,
          },
          {
            path: "/administrator/proposals/cancel-lockup",
            element: <AdministratorCancelLockupProposalPage />,
          },
          {
            path: "/administrator/proposals/transfer-token-ownership",
            element: <AdministratorTransferTokenOwnershipProposalPage />,
          },
          {
            path: "/administrator/proposals/extend-lockup-period",
            element: <AdministratorExtendLockupPeriodProposalPage />,
          },
        ],
      },
      {
        path: "/manager",
        element: <ManagerLayout />,
        children: [
          {
            path: "/manager/operators",
            element: <ManagerOperatorPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
